import React from 'react';
import NumberFormat from 'react-number-format';
import {Button, Card, Checkbox, Dialog, FormGroup, HTMLSelect, Spinner, TextArea} from "@blueprintjs/core";
import bbpos_swipe_insert from '../images/bbpos_swipe_insert.png'
import bbpos_swipe_insert_tap from '../images/bbpos_swipe_insert_tap.png'
import paymentConfirmation from '../images/icons8-checked-48.png'
import receiptConfirmation from '../images/icons8-receipt-approved-64.png'
import processingError from '../images/icons8-high-importance-80.png'
import defaults from "../config/defaults";
import TerminalRestClient from "./terminalRestClient";
import * as Classes from "@blueprintjs/core/lib/cjs/common/classes";
import IdleTimer from "react-idle-timer";
import {Redirect} from "react-router-dom";
import * as PubSub from "pubsub-js";
import {Elevation} from "@blueprintjs/core/lib/cjs/common/elevation";
import constants from "../config/constants";
import Logger from "./utils/Logger";

class Donate extends React.Component {

    constructor(props) {
        super(props);
        this.customerDetailsForm = React.createRef()
        this.customerDetailsSubscribeForm = React.createRef()
        this.intervalId = 0
        this.idleTimer = null
        this.initialDonationStateWithoutCause = {
            // idle timer
            redirectToOrganizationInput: false,
            timeout: defaults.idleTimeoutInSecs * 1000,
            isIdle: false,

            // donation form
            monthly: false,
            organizationPublicId: '',
            amount: 0,
            customerId: '',
            customerEmail: '',
            customerFirstName: '',
            customerLastName: '',
            customerPhone: '',
            notes: ''
        }
        this.initialDonationState = {
            ...this.initialDonationStateWithoutCause
        }
        this.initialTerminalState = {
            readerConnectionStatus: "not_connected",
            readerDisplay: "Reader not connected.",
            readerLabel: "",
            readerInitializing: false,
        }
        this.initialTerminalTransactionState = {
            errorMessage: "",
            cancelablePayment: false,
            transactionInProgress: false,
            showModal: false,
            transactionTrackingId: ""
        }
        this.organizationDetails = {
            organizationPublicId: this.props.match.params.organizationPublicId
        }
        this.state = {
            ...this.initialDonationState,
            ...this.initialTerminalState,
            ...this.initialTerminalTransactionState,
            ...this.organizationDetails,
            readerConnectOrganization: this.props.match.params.organizationPublicId
        }
        this.initializeBackendClientAndTerminal(defaults.remoteBaseUrl, this.organizationDetails.organizationPublicId)
        this.causes = [];
        this.logger = new Logger()
    }

    initializeBackendClientAndTerminal = (url, organizationId) => {
        this.client = new TerminalRestClient(url);
        // stripe reader init
        try {
            const event = {
                "event": "initializeTerminalForOrganization",
                "data": {
                    "organizationPublicId": organizationId,
                    "simulated": defaults.simulated
                }
            }
            window.ReactNativeWebView.postMessage(JSON.stringify(event));

            if(organizationId.toLowerCase() !== this.state.readerConnectOrganization.toLowerCase()){
                // alert(organizationId + " " +this.state.readerConnectOrganization)
                this.setState({readerInitializing: true})
                this.setState({readerConnectOrganization: organizationId.toLowerCase()})
            }
        } catch (e) {
            console.log("Mobile native context not found.")
            this.setState({readerInitializing: false})
        }
        PubSub.subscribe('goodbricks.readerConnected', (msg, data) => {
            this.setState({
                readerConnectionStatus: 'connected',
                readerDisplay: "Reader Connected.",
                readerLabel: "..." + (data.serialNumber).slice(-5),
                readerBatteryLevel: Number.parseFloat(data.batteryLevel).toFixed(2) * 100,
                readerInitializing: false
            });
        });
        PubSub.subscribe('goodbricks.readerDisconnected', (msg, data) => {
            this.setState({readerConnectionStatus: 'not_connected'});
            this.setState({readerDisplay: data.message});
        });
        PubSub.subscribe('goodbricks.readerDisplayUpdated', (msg, data) => {
            this.setState({readerDisplay: data});
        });
        PubSub.subscribe('goodbricks.showCardInputOptions', (msg, data) => {
            if (this.state.monthly) {
                this.setState({userActionModal: constants.USER_ACTION_MODAL_SHOW_CARD_INPUT_OPTIONS_SUBSCRIBE});
            } else {
                this.setState({userActionModal: constants.USER_ACTION_MODAL_SHOW_CARD_INPUT_OPTIONS_TRANSACTION});
            }
        });
        PubSub.subscribe('goodbricks.showProcessingError', (msg, data) => {
            this.setState({userActionModal: constants.USER_ACTION_MODAL_SHOW_ERROR});
            this.setState({errorMessage: data});
        });
        PubSub.subscribe('goodbricks.showRemoveCard', (msg, data) => {
            this.setState({userActionModal: constants.USER_ACTION_MODAL_SHOW_REMOVE_CARD});
        });
        PubSub.subscribe('goodbricks.updateReaderInfo', (msg, data) => {
            this.setState({
                readerConnectionStatus: 'connected',
                readerDisplay: "Reader Connected.",
                readerLabel: "..." + (data.serialNumber).slice(-5)
            });
        });
    };

    async componentWillMount() {
        defaults.durationOptions.forEach(duration => {
            if (duration.default) {
                this.initialDonationState = {
                    ...this.initialDonationState,
                    duration: duration.value,
                    durationLabel: duration.label
                }
                this.setState({duration: duration.value})
                this.setState({durationLabel: duration.label})
            }
        });
        let defaultCauseSet = false;
        this.causes = await this.client.retrieveOrganizationCauses(this.organizationDetails.organizationPublicId);
        this.causes.forEach(cause => {
            if (cause.default || !defaultCauseSet) {
                defaultCauseSet = true;
                this.initialDonationState = {...this.initialDonationState, cause: cause.id, causeLabel: cause.name}
                this.setState({cause: cause.id});
                this.setState({causeLabel: cause.name});
            }
        });
        this.initialDonationState = {...this.initialDonationState, ...this.organizationDetails}
    }

    componentDidMount() {
        this.intervalId = setInterval(this.updateReaderInfo, 60000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
        PubSub.clearAllSubscriptions();
    }

    updateReaderInfo = () => {
        if (this.state.readerConnectionStatus === 'connected') {
            // window.webkit.messageHandlers.getConnectedReaderInfo.postMessage({});
            const event = {
                "event": "getConnectedReaderInfo",
                "data": {
                    "organizationPublicId": this.readerConnectOrganization,
                    "simulated": defaults.simulated
                }
            }
            window.ReactNativeWebView.postMessage(JSON.stringify(event));
        }
    }

    discoverReaders = () => {
        try {
            // window.webkit.messageHandlers.discoverAndConnectReader.postMessage({simulated: defaults.simulated});
            const event = {
                "event": "discoverAndConnectReader",
                "data": {
                    "organizationPublicId": this.organizationDetails.organizationPublicId,
                    "simulated": defaults.simulated
                }
            }
            window.ReactNativeWebView.postMessage(JSON.stringify(event));
        } catch (e) {
        }
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.currentTarget.blur();
        }
    }

    handleCauseButtonClick = (cause, causeLabel, organizationPublicId) => {
        this.setState({cause: cause});
        this.setState({causeLabel: causeLabel});
        this.initializeBackendClientAndTerminal(defaults.remoteBaseUrl, organizationPublicId)
    }

    handleMonthlyChange = (event) => {
        this.setState({monthly: event.currentTarget.checked});
    }

    handleCustomAmountInput = (values) => {
        // clear storage
        if (values.value == 0.00705001) {
            this.resetDonationFormAndPaymentIntent();
            localStorage.clear();
            this.setState({redirectToOrganizationInput: true});
            return
        }
        // refresh
        if (values.value == 0.00705002) {
            window.location.reload();
            return
        }
        this.setState({amount: values.value});
    }

    handleAmountButtonClick = (amount) => {
        this.setState({amount});
    }

    handleDurationChange = (event) => {
        this.setState({duration: event.currentTarget.value});
        this.setState(
            {durationLabel: event.currentTarget.selectedOptions[0].label});
    }

    handleCustomerFirstNameChange = (event) => {
        this.setState({customerFirstName: event.currentTarget.value});
    }

    handleCustomerLastNameChange = (event) => {
        this.setState({customerLastName: event.currentTarget.value});
    }

    handleCustomerEmailChange = (event) => {
        this.setState({customerEmail: event.currentTarget.value});
    }

    handleNotesChange = (event) => {
        this.setState({notes: event.currentTarget.value});
    }

    formatCurrency = (number) => {
        return Intl.NumberFormat('us-US',
            {
                style: 'currency',
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
                currency: 'USD'
            }).format(number);
    }

    handleCustomerDetailsSubmit = () => {
        if (this.validateCustomerDetailsForm()) {
            this.setState({userActionModal: constants.USER_ACTION_MODAL_SHOW_PROCESSING});
            this.collectCardPayment();
        }
    }

    handleCustomerDetailsSubscribeSubmit = () => {
        if (this.validateCustomerDetailsSubscribeForm()) {
            this.setState({userActionModal: constants.USER_ACTION_MODAL_SHOW_PROCESSING});
            this.subscribe();
        }
    }

    handleCustomerDetailsSubmitSkip = () => {
        this.setState({customerFirstName: null})
        this.setState({customerLastName: null})
        this.setState({customerEmail: null})
        this.setState({userActionModal: constants.USER_ACTION_MODAL_SHOW_PROCESSING});
        this.collectCardPayment();
    }

    handleOnCloseCancel = async () => {
        try {
            await this.client.cancelPaymentIntent(this.paymentIntentId);
        } catch (e) {
            // Ignore, since it can't be canceled anyways. Nothing more to do here.
        }
        this.setState({...this.initialDonationStateWithoutCause, ...this.initialTerminalTransactionState});
        this.paymentIntentId = null;
        this.paymentIntentIdCaptured = false;
        this.paymentMethodId = null;
        this.paymentMethodIdCaptured = false;
        this.setState({amount: 0});
        if (this.state.readerConnectionStatus === 'connected') {
            this.setState({readerDisplay: "Reader Connected."})
        }
        this.logger.log({
            event: "process_payment__reset",
            data: {
                state: this.state
            }
        });
    }

    resetDonationFormAndPaymentIntent = async () => {
        try {
            await this.client.cancelPaymentIntent(this.paymentIntentId);
        } catch (e) {
            // Ignore, since it can't be canceled anyways. Nothing more to do here.
        }
        this.setState({...this.initialDonationState, ...this.initialTerminalTransactionState});
        this.paymentIntentId = null;
        this.paymentIntentIdCaptured = false;
        this.paymentMethodId = null;
        this.paymentMethodIdCaptured = false;
        this.setState({amount: 0});
        this.initializeBackendClientAndTerminal(defaults.remoteBaseUrl, this.organizationDetails.organizationPublicId)
        if (this.state.readerConnectionStatus === 'connected') {
            this.setState({readerDisplay: "Reader Connected."})
        }
        this.logger.log({
            event: "process_payment__reset",
            data: {
                state: this.state
            }
        });
    }

    validateCustomerDetailsForm = () => {
        return this.customerDetailsForm.current.reportValidity();
    }

    validateCustomerDetailsSubscribeForm = () => {
        return this.customerDetailsSubscribeForm.current.reportValidity();
    }

    processPayment = async () => {
        if (this.state.cause === 'other' && !this.state.notes) {
            this.setState({errorMessage: "Notes cannot be blank when cause is \"Other\"."})
            return;
        }
        this.setState({
            transactionInProgress: true,
            transactionTrackingId: Date.now(),
            showModal: true,
            userActionModal: constants.USER_ACTION_MODAL_SHOW_PROCESSING
        });

        if (this.state.monthly) {
            await this.subscribe();
        } else {
            await this.collectCardPayment();
        }
    }

    collectCardPayment = async () => {
        try {
            // We want to create new PaymentIntent object in the case of declined charges. Continue transaction
            if (!this.paymentIntentIdCaptured) {

                this.logger.log({
                    event: "process_payment__create_payment_intent",
                    data: {
                        organizationPublicId: this.state.readerConnectOrganization,
                        state: this.state
                    },
                });

                this.paymentIntentId = await this.client.createPaymentIntent({
                    amount: this.state.amount,
                    organizationPublicId: this.state.readerConnectOrganization,
                    incomeCategoryId: this.state.cause,
                    monthly: this.state.monthly,
                    durationInMonths: this.state.durationOptions
                });

                this.logger.log({
                    event: "process_payment__payment_intent_created",
                    data: {
                        paymentIntentId: this.paymentIntentId,
                        state: this.state
                    }
                });

                // get existing customer or replaceable customer from paymentIntent
                const customerResult = await this.client.retrieveCustomerByPaymentIntent({
                    paymentIntentId: this.paymentIntentId,
                    organizationPublicId: this.state.readerConnectOrganization
                });

                // save paymentIntentId until we get customer info from the prompt
                this.paymentIntentIdCaptured = true;
                if (!customerResult.found) {
                    this.setState({userActionModal: constants.USER_ACTION_MODAL_SHOW_CUSTOMER_DETAILS});

                    this.logger.log({
                        event: "process_payment__customer_not_found",
                        data: {
                            paymentIntentId: this.paymentIntentId,
                            state: this.state
                        }
                    });

                    return;
                } else {
                    this.setState({customerEmail: customerResult.customer.email})

                    this.logger.log({
                        event: "process_payment__customer_found",
                        data: {
                            paymentIntentId: this.paymentIntentId,
                            customerEmail: customerResult.customer.email,
                            state: this.state
                        }
                    });

                }
            }

            var donationRequest = {
                paymentIntentId: this.paymentIntentId,
                organizationPublicId: this.state.readerConnectOrganization,
                customerEmail: this.state.customerEmail,
                customerFirstName: this.state.customerFirstName,
                customerLastName: this.state.customerLastName,
                customerPhone: this.state.customerPhone,
                categoryName: this.state.cause,
                categoryAmount: this.state.amount,
                subscribe: this.state.monthly,
                paymentIterations: this.state.duration,
                notes: this.state.notes
            };
            // Capture the PaymentIntent from your backend client and mark the payment as complete
            const captureResult = await this.client.capturePaymentIntent(donationRequest);

            this.logger.log({
                event: "process_payment__captured_payment_intent",
                data: {
                    paymentIntentId: this.paymentIntentId,
                    donationRequest: donationRequest,
                    state: this.state
                }
            });

            this.setState({readerDisplay: "Payment Successful!"});
            this.setState({userActionModal: constants.USER_ACTION_MODAL_SHOW_CONFIRMATION});
            return captureResult;
        } catch (e) {
            console.log(e);
            this.logger.log({
                event: "process_payment__error",
                data: {
                    error: e.message,

                    state: this.state
                }
            });
            this.resetDonationFormAndPaymentIntent();
            this.setState({errorMessage: e.message});
            return;
        }
    };

    subscribe = async () => {
        try {
            if (!this.paymentMethodIdCaptured) {

                this.paymentMethodId = await this.client.createPaymentMethod();

                const customerResult = await this.client.retrieveCustomerByPaymentMethod({
                    sourceId: this.paymentMethodId,
                    organizationPublicId: this.state.readerConnectOrganization
                });
                this.sourceId = this.paymentMethodId;
                this.paymentMethodIdCaptured = true;
                if (!customerResult.found) {
                    this.setState({userActionModal: constants.USER_ACTION_MODAL_SHOW_SUBSCRIBE_DETAILS});
                    return;
                } else {
                    this.setState({customerEmail: customerResult.customer.email})
                }

            }
            // Then, pass the source to your backend client to save it to a customer
            let customer = await this.client.subscribe({
                sourceId: this.sourceId,
                organizationPublicId: this.state.readerConnectOrganization,
                customerEmail: this.state.customerEmail,
                customerFirstName: this.state.customerFirstName,
                customerLastName: this.state.customerLastName,
                customerPhone: this.state.customerPhone,
                categoryName: this.state.cause,
                categoryAmount: this.state.amount,
                subscribe: this.state.monthly,
                paymentIterations: this.state.duration
            });
            console.log("Subscribed Customer!", customer);
            this.setState({userActionModal: constants.USER_ACTION_MODAL_SHOW_CONFIRMATION});
            return customer;
        } catch (e) {
            console.log(e);
            this.logger.log({
                event: "process_payment__error",
                data: {
                    error: e.message,

                    state: this.state
                }
            });
            this.resetDonationFormAndPaymentIntent();
            this.setState({errorMessage: e.message});
            return;
        }
    };

    onIdle = () => {
        this.resetDonationFormAndPaymentIntent();
    }

    render() {
        if (this.state.redirectToOrganizationInput === true) {
            return <Redirect to="/"/>
        }
        return (
            <div className="row">
                <div id="left-content"
                     className="section-flex eight columns py-2 px-4 bg-lighter">
                    <div className="row py-1">
                        <div className="twelve columns" style={{paddingRight: '2rem'}}>
                            <h4 className='bp3-heading py-1'> 1. Enter an amount </h4>
                            <NumberFormat id='amount' className='border-radius-none'
                                          placeholder="$0"
                                          thousandSeparator={true} prefix={'$'} type='tel'
                                          value={this.state.amount ? this.state.amount : ''}
                                // disabled={this.state.readerConnectionStatus !== 'connected'}
                                          onValueChange={this.handleCustomAmountInput}
                                          onKeyPress={this.handleKeyPress}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="twelve columns">
                            <div className="linear-flow">
                                {
                                    defaults.amountButtons.map(amount =>
                                        <Button key={amount} large
                                                disabled={this.state.readerConnectionStatus !== 'connected'}
                                                intent={parseInt(this.state.amount) === amount
                                                    ? 'primary' : 'none'}
                                                onClick={() => this.handleAmountButtonClick(
                                                    amount)}>
                                            {this.formatCurrency(amount)}
                                        </Button>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="twelve columns">
                            <Checkbox label="Monthly" large inline className='mx-0'
                                      disabled={this.state.readerConnectionStatus !== 'connected'}
                                      onChange={this.handleMonthlyChange} checked={this.state.monthly}/>

                                <HTMLSelect minimal large onChange={this.handleDurationChange}
                                            value={this.state.duration}
                                            disabled={this.state.readerConnectionStatus !== 'connected'}
                                >
                                    {defaults.durationOptions.map(duration => {
                                        return <option key={duration.value} value={duration.value}
                                            // defaultValue={duration.value === this.state.duration}
                                                       label={duration.label}> {duration.label} </option>

                                    })}
                                </HTMLSelect>
                        </div>
                    </div>
                    <div className="row py-2">
                        <div className="twelve columns">
                            <h4 className='bp3-heading row py-1'> 2. Select a category </h4>
                            <div id='causes' className='linear-flow'>
                                {
                                    this.causes.map(cause => {
                                        return <Button key={cause.id}
                                                       disabled={this.state.readerConnectionStatus !== 'connected'}
                                                       onClick={() => this.handleCauseButtonClick(
                                                           cause.id, cause.name, cause.organizationPublicId)}
                                                       style={{textTransform: 'capitalize'}}
                                                       intent={this.state.cause === cause.id
                                                           ? 'primary' : 'none'}>
                                            {cause.name}
                                        </Button>
                                    })
                                }
                            </div>
                            <FormGroup style={{paddingRight: '2rem'}} label="Notes" labelFor="text-input"
                                       labelInfo="(Optional)">
                                <TextArea id="text-input-notes"
                                          disabled={this.state.readerConnectionStatus !== 'connected'}
                                          className="bp3-fill" growVertically placeholder="Any requests or comments... "
                                          value={this.state.notes} onChange={this.handleNotesChange}
                                          onKeyPress={this.handleKeyPress}/>
                            </FormGroup>
                        </div>
                    </div>
                </div>
                <div id="right-content"
                     className="section-flex four columns py-2 px-4">
                    {this.state.readerConnectionStatus
                    && this.state.readerConnectionStatus.toLowerCase() === 'connected' ?
                        <div id="donate-reader-connected" className='px-1'>
                            <div className="row">
                                <div className="twelve columns">
                                    <h4 className='bp3-heading py-2'>Summary</h4>
                                </div>
                            </div>
                            <div className="row py-2">
                                <div className="twelve columns">
                                    <h5 className='bp3-heading'>You are paying <span
                                        className='text-primary'>{this.formatCurrency(
                                        this.state.amount)}</span></h5>
                                    <h5 className='bp3-heading'>{!this.state.monthly
                                        && 'one time'}
                                        {this.state.monthly && 'monthy for'} <span
                                            className='text-primary'>{this.state.monthly
                                            && this.state.durationLabel}</span></h5>
                                    <h5 className='bp3-heading'>towards <span
                                        className='text-primary'>{this.state.causeLabel}</span>
                                    </h5>
                                    <h3>
                                        {this.state.transactionStatus}
                                    </h3>
                                </div>
                            </div>
                            <div className="row py-2">
                                <div className="twelve columns">
                                    {
                                        this.state.readerInitializing ?
                                            <Button intent="success" large fill disabled={true}>
                                        Initializing...</Button>
                                        : <Button intent="success" large fill
                                            disabled={!this.state.amount > 0 || this.state.amount == 0}
                                            loading={this.state.transactionInProgress}
                                            onClick={this.processPayment}>
                                        Pay {this.formatCurrency(
                                        this.state.amount)}{this.state.monthly
                                        && '/m'}</Button>
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <div id="discover-readers">
                            <div className="row py-2">
                                <div className="twelve columns">
                                    <Button intent="success" large fill onClick={this.discoverReaders}>Discover
                                        Readers</Button>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row py-2">
                        <div className="twelve columns">
                            {this.state.readerConnectionStatus !== 'connected' ?
                                'Card Reader Status'
                                :
                                <h5 className="bp3-heading">
                                    {this.state.readerBatteryLevel && <div className="bp3-inline u-pull-right">{this.state.readerBatteryLevel}%</div>}
                                    Connected to {this.state.readerLabel}
                                </h5>
                            }

                            <Card interactive={true} elevation={Elevation.ZERO}>
                                <p> [{new Date().toLocaleTimeString()}] {this.state.readerConnectOrganization} <br/>{this.state.readerDisplay}</p>

                            </Card>
                        </div>
                    </div>
                </div>
                <Dialog className='primary' onClose={this.handleOnCloseCancel} title={"Processing..."}
                        isOpen={this.state.showModal}>
                    <div>
                        {(() => {
                            switch (this.state.userActionModal) {
                                case constants.USER_ACTION_MODAL_SHOW_CARD_INPUT_OPTIONS_TRANSACTION:
                                    return <div>
                                        <div className={Classes.DIALOG_BODY}>
                                            <div className="row py-4">
                                                <div
                                                    className="linear-flow flex-justify-content-center">
                                                    <img src={bbpos_swipe_insert_tap} alt='Insert'
                                                         style={{marginLeft: '38px'}} width='auto' height='150'
                                                         className={'pulse'}/>
                                                </div>
                                                <p className="bp3-heading text-center py-2 bp3-running-text py-2"><b>Insert,
                                                    Swipe or Tap</b> a payment card <br/>to complete transaction</p>
                                            </div>
                                        </div>
                                    </div>;
                                case constants.USER_ACTION_MODAL_SHOW_CARD_INPUT_OPTIONS_SUBSCRIBE:
                                    return <div>
                                        <div className={Classes.DIALOG_BODY}>
                                            <div className="row py-4">
                                                <div
                                                    className="linear-flow flex-justify-content-center">
                                                    <img src={bbpos_swipe_insert} alt='Insert'
                                                         style={{marginLeft: '38px'}} width='auto' height='150'
                                                         className={'pulse'}/>
                                                </div>
                                                <p className="bp3-heading text-center py-2 bp3-running-text py-2"><b>Insert
                                                    or Swipe </b> a payment card <br/>to complete subscription</p>
                                            </div>
                                        </div>
                                    </div>;
                                case constants.USER_ACTION_MODAL_SHOW_REMOVE_CARD:
                                    return <div>
                                        <div className={Classes.DIALOG_BODY} style={{paddingTop: '10rem'}}>
                                            <div className="row py-4">
                                                <div className="linear-flow flex-justify-content-center">
                                                    <Spinner intent={'primary'} size={Spinner.SIZE_STANDARD}/>
                                                </div>
                                                <p className="bp3-heading text-center py-2 bp3-running-text py-2"> Remove
                                                    Card</p>
                                            </div>
                                        </div>
                                    </div>;
                                case constants.USER_ACTION_MODAL_SHOW_CUSTOMER_DETAILS:
                                    return <div>
                                        <div className={Classes.DIALOG_BODY}>
                                            <div className="row py-1">
                                                <div className=" flex flex-justify-content-center">
                                                    <img src={receiptConfirmation} alt='Do you need a receipt?'/>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <h5 className="text-center">
                                                    Would you like a receipt?
                                                </h5>
                                            </div>
                                            <div className="row py-1">
                                                <form ref={this.customerDetailsForm}>
                                                    <div className="row py-1">
                                                        <div className="six columns">
                                                            <input className="bp3-input bp3-fill" type="text"
                                                                   placeholder='First Name'
                                                                   onChange={this.handleCustomerFirstNameChange}
                                                                   pattern="[a-zA-Z0-9 -]{2,}"
                                                                   onKeyPress={this.handleKeyPress} required/>
                                                            <span
                                                                className="requirements">Enter a valid first name.</span>
                                                        </div>
                                                        <div className="six columns">
                                                            <input className="bp3-input bp3-fill" type="text"
                                                                   placeholder='Last Name'
                                                                   onChange={this.handleCustomerLastNameChange}
                                                                   pattern="[a-zA-Z0-9 -]{2,}"
                                                                   onKeyPress={this.handleKeyPress} required/>
                                                            <span
                                                                className="requirements">Enter a valid last name.</span>
                                                        </div>
                                                    </div>
                                                    <div className="row py-1">
                                                        <div className="twelve columns">
                                                            <input className="bp3-input bp3-fill" type="email"
                                                                   placeholder='Email'
                                                                   onChange={this.handleCustomerEmailChange}
                                                                   onKeyPress={this.handleKeyPress} required/>
                                                            <span
                                                                className="requirements">Enter a valid email address.</span>
                                                        </div>
                                                    </div>
                                                    <div className="row py-1 flex flex-justify-content-center py-2'">
                                                        <Button onClick={this.handleCustomerDetailsSubmitSkip}
                                                                minimal> No, Thanks </Button>
                                                        <Button onClick={this.handleCustomerDetailsSubmit}
                                                                intent="success">Yes, Please</Button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>;
                                case constants.USER_ACTION_MODAL_SHOW_SUBSCRIBE_DETAILS:
                                    return <div>
                                        <div className={Classes.DIALOG_BODY}>
                                            <div className="row py-1">
                                                <div className=" flex twelve columns flex-justify-content-center">
                                                    <img src={receiptConfirmation} alt='Do you need a receipt?'/>
                                                </div>
                                            </div>
                                            <h5 className="text-center">
                                                Please enter your details below...
                                            </h5>
                                            <form ref={this.customerDetailsSubscribeForm}>
                                                <div className="row py-1">
                                                    <div className="six columns ">
                                                        <input className="bp3-input bp3-fill" type="text"
                                                               placeholder='First Name'
                                                               onChange={this.handleCustomerFirstNameChange}
                                                               onKeyPress={this.handleKeyPress}
                                                               pattern="[a-zA-Z0-9 -]{2,}" required/>
                                                        <span className="requirements">Enter a valid first name.</span>
                                                    </div>
                                                    <div className="six columns">
                                                        <input className="bp3-input bp3-fill" type="text"
                                                               placeholder='Last Name'
                                                               onChange={this.handleCustomerLastNameChange}
                                                               onKeyPress={this.handleKeyPress}
                                                               pattern="[a-zA-Z0-9 -]{2,}" required/>
                                                        <span className="requirements">Enter a valid last name.</span>
                                                    </div>
                                                </div>
                                                <div className="row py-1">
                                                    <div className="twelve columns">
                                                        <input className="bp3-input bp3-fill" type="email"
                                                               placeholder='Email'
                                                               onChange={this.handleCustomerEmailChange}
                                                               onKeyPress={this.handleKeyPress} required/>
                                                        <span
                                                            className="requirements">Enter a valid email address.</span>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className='flex flex-justify-content-center py-1'>
                                                <Button onClick={this.handleCustomerDetailsSubscribeSubmit}
                                                        intent="success">Submit</Button>
                                            </div>
                                        </div>
                                    </div>;
                                case constants.USER_ACTION_MODAL_SHOW_CONFIRMATION:
                                    return <div>
                                        <div className={Classes.DIALOG_BODY}>
                                            <div className="row py-2">
                                                <div className=" flex flex-justify-content-center">
                                                    <img src={paymentConfirmation} alt='Donation Confirmation'/>
                                                </div>
                                            </div>
                                            <p className="bp3-running-text text-center py-1 bp3-text-large">
                                                Thank you for your payment.
                                            </p>
                                            <p className="bp3-running-text text-center bp3-text-large">
                                                Receipt is sent to registered email address.
                                            </p>
                                            <div className="flex flex-justify-content-center py-2">
                                                <Button
                                                    onClick={this.resetDonationFormAndPaymentIntent}> Close </Button>
                                            </div>
                                        </div>
                                    </div>;
                                case constants.USER_ACTION_MODAL_SHOW_ERROR:
                                    return <div>
                                        <div className={Classes.DIALOG_BODY}>
                                            <div className="row py-2">
                                                <div className=" flex twelve columns flex-justify-content-center">
                                                    <img src={processingError} alt='Processing error'/>
                                                </div>
                                            </div>
                                            <h6 className="text-center py-2">
                                                {this.state.errorMessage}
                                            </h6>
                                        </div>
                                        <div className="flex flex-justify-content-center py-1'">
                                            <Button onClick={this.resetDonationFormAndPaymentIntent}> Close </Button>
                                        </div>
                                    </div>;
                                default:
                                    return <div>
                                        <div className={Classes.DIALOG_BODY} style={{paddingTop: '10rem'}}>
                                            <div className="row py-4">
                                                <div className="linear-flow flex-justify-content-center">
                                                    <Spinner intent={'primary'} size={Spinner.SIZE_STANDARD}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>;
                            }
                        })()}
                    </div>
                </Dialog>
                <IdleTimer
                    ref={ref => {
                        this.idleTimer = ref
                    }}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    timeout={this.state.timeout}
                    startOnLoad/>
            </div>
        );
    }

}
;

export default Donate;
