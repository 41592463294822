// Client for the example terminal backend: https://github.com/stripe/example-terminal-backend
import * as PubSub from "pubsub-js";

class TerminalRestClient {
  constructor(url) {
    this.url = url;
  }

  retrieveOrganization(organizationPublicId) {
    return this.doGet(this.url + "/api/public/vendorOrganization/" + organizationPublicId);
  }

  async retrieveOrganizationCauses(organizationPublicId) {
    const initialCauses = await this.doGet(this.url + "/api/public/vendorOrganization/" + organizationPublicId + "/publicCategories");
    let causes = initialCauses.map(x => ({
      ...x,
      organizationPublicId: organizationPublicId
    }))
    // if (organizationPublicId.toString().toLowerCase() === "icsd.org") {
    //   causes.push({
    //     "id": "zakat",
    //     "name": "Zakaat Al Maal",
    //     "text": "Zakaat Al Maal",
    //     organizationPublicId: "zakat.icsd.org"
    //   })
    // }
    return causes
  }

  createConnectionToken(organizationPublicId) {
    const formData = new URLSearchParams();
    formData.append("organizationPublicId", organizationPublicId);
    return this.doPost(this.url + "/api/stripe/connection_token", formData);
  }

  createPaymentIntent(paymentIntent) {
    // Get the current 'global' time from an API using Promise
    return new Promise((resolve, reject) => {
      const event = {
        "event":"createPaymentIntent",
        "data":{
          paymentIntent
        }
      }
      window.ReactNativeWebView.postMessage(JSON.stringify(event));
      // window.webkit.messageHandlers.createPaymentIntent.postMessage({amount: paymentIntent.amount});
      PubSub.subscribe('goodbricks.paymentIntentCreated', (msg, data) => {
        resolve(data);
      });
    })
  }

  createPaymentMethod() {
    // Get the current 'global' time from an API using Promise
    return new Promise((resolve, reject) => {
      const event = {
        "event":"createPaymentMethod",
        "data":{}
      }
      window.ReactNativeWebView.postMessage(JSON.stringify(event));
      // window.webkit.messageHandlers.createPaymentMethod.postMessage({});
      PubSub.subscribe('goodbricks.paymentMethodCreated', (msg, data) => {
        resolve(data);
      });
    })
  }

  retrieveCustomerByPaymentIntent({organizationPublicId, paymentIntentId}) {
    const formData = new URLSearchParams();
    formData.append("paymentIntentId", paymentIntentId);
    formData.append("organizationPublicId", organizationPublicId);
    return this.doPost(this.url + "/api/stripe/retrieve_customer", formData);
  }

  retrieveCustomerByPaymentMethod({organizationPublicId, sourceId}) {
    const formData = new URLSearchParams();
    formData.append("sourceId", sourceId);
    formData.append("organizationPublicId", organizationPublicId);
    return this.doPost(this.url + "/api/stripe/retrieve_customer/payment_method", formData);
  }

  capturePaymentIntent(donationRequest) {
    const formData = this._createDonationRequest(donationRequest)
    return this.doPost(this.url + "/api/stripe/capture_payment_intent", formData);
  }

  cancelPaymentIntent(paymentIntentId){
    return new Promise((resolve, reject) => {
      const event = {
        "event":"cancelPaymentIntent",
        "data":{paymentIntentId}
      }
      window.ReactNativeWebView.postMessage(JSON.stringify(event));
      // window.webkit.messageHandlers.cancelPaymentIntent.postMessage({});
      PubSub.subscribe('goodbricks.paymentIntentCancelled', (msg, data) => {
        resolve(data.message);
      });
    })
  }

  subscribe(donationRequest) {
    const formData = this._createDonationRequest(donationRequest)
    return this.doPost(this.url + "/api/stripe/capture_payment_intent", formData);
  }

  _createDonationRequest(donationRequest) {
    const formData = new URLSearchParams();
    formData.append("sourceId", donationRequest.sourceId);
    formData.append("paymentIntentId", donationRequest.paymentIntentId);
    formData.append("organizationPublicId", donationRequest.organizationPublicId);
    formData.append("customerEmail", donationRequest.customerEmail);
    formData.append("customerFirstName", donationRequest.customerFirstName);
    formData.append("customerLastName", donationRequest.customerLastName);
    formData.append("customerPhone", donationRequest.customerPhone);
    formData.append("categoryName", donationRequest.categoryName);
    formData.append("categoryAmount", donationRequest.categoryAmount);
    formData.append("subscribe", donationRequest.subscribe);
    formData.append("paymentIterations", donationRequest.paymentIterations);
    formData.append("notes", donationRequest.notes);
    return formData
  }

  async doPost(url, body) {
    let response = await fetch(url, {
      method: "post",
      body: body
    });

    if (response.ok) {
      return response.json();
    } else {
      let text = await response.text();
      throw new Error("Request Failed: " + text);
    }
  }

  async doGet(url) {
    let response = await fetch(url, {
      method: "get"
    });

    if (response.ok) {
      return response.json();
    } else {
      let text = await response.text();
      // throw new Error("Request Failed: " + text);
    }
  }

}

export default TerminalRestClient;
